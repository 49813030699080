import { ResolveFn } from '@angular/router';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import { inject } from '@angular/core';
import { NavigatorService } from '@core/routing/routes/services/navigator.service';
import { RouteParam } from '@core/routing/routes/enums/route-param';
import { isNil } from '@core/utils/nil/is-nil';
import { Route } from '@core/routing/routes/enums/route';
import { EquipmentReport } from '@features/equipment/models/equipment-report';
import { EquipmentReportActionService } from '@features/equipment/services/equipment-report-action.service';

export const EquipmentReportResolver: ResolveFn<EquipmentReport> = (
  route
): Observable<EquipmentReport> => {
  const action = inject(EquipmentReportActionService);
  const navigator = inject(NavigatorService);
  const equipmentId = route.parent?.paramMap.get(RouteParam.EquipmentId);
  const id = route.paramMap.get(RouteParam.ReportId);

  return of(equipmentId).pipe(
    switchMap(equipmentId => {
      if (isNil(equipmentId) || isNil(id)) {
        navigator.navigateByRouterLink(provider =>
          provider.routerLink(Route.NotFound)
        );

        return EMPTY;
      }

      return action.fetch(equipmentId, id);
    })
  );
};
