import { EquipmentActionService } from '@features/equipment/services/equipment-action.service';
import { ResolveFn } from '@angular/router';
import { Equipment } from '@features/equipment/models/equipment';
import { inject } from '@angular/core';
import { NavigatorService } from '@core/routing/routes/services/navigator.service';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import { RouteParam } from '@core/routing/routes/enums/route-param';
import { Route } from '@core/routing/routes/enums/route';
import { isNil } from '@core/utils/nil/is-nil';

export const EquipmentResolver: ResolveFn<Equipment> = (
  route
): Observable<Equipment> => {
  const action = inject(EquipmentActionService);
  const navigator = inject(NavigatorService);
  const id = route.paramMap.get(RouteParam.EquipmentId);

  return of(id).pipe(
    switchMap(id => {
      if (isNil(id)) {
        navigator.navigateByRouterLink(provider =>
          provider.routerLink(Route.NotFound)
        );

        return EMPTY;
      }

      return action.fetch(id);
    })
  );
};
