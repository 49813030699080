import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfirmationComponent } from '@shared/confirmation/confirmation.component';
import { ToastComponent } from '@shared/toast/toast.component';
import { WebsocketService } from '@api/websocket/websocket.service';
import { AuthService } from '@core/auth/services/auth.service';
import { filter, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, ConfirmationComponent, ToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly auth: AuthService,
    private readonly websocket: WebsocketService
  ) {}

  ngOnInit(): void {
    this.auth
      .isLoggedIn()
      .pipe(
        filter(Boolean),
        switchMap(() => this.websocket.connect()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
